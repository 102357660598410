import { Component } from '@angular/core';

import * as microsoftTeams from '@microsoft/teams-js';

import { authLabels } from 'src/app/shared/labels';
import { AuthloaderService } from 'src/app/shared/services/authloader.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'wg-auth-popup',
  templateUrl: './auth-popup.component.html',
  styleUrls: ['./auth-popup.component.css']
})
export class AuthPopupComponent {
  constructor(private LoaderService: AuthloaderService) {
    microsoftTeams.app.initialize();
    microsoftTeams.getContext(() => {
      const queryParams: Record<string, string> = {
        client_id: environment.AAD_LOGIN_PARAMS.CLIENT_ID,
        response_type: authLabels.response_type,
        response_mode: authLabels.response_mode,
        scope: environment.AAD_LOGIN_PARAMS.SCOPES,
        redirect_uri: window.location.origin + '/auth/auth-end',
        nonce: environment.AAD_LOGIN_PARAMS.NONCE,
        state: '12345'
      };

      let authorizeEndpoint = environment.AAD_LOGIN_PARAMS.AD_URL + new URLSearchParams(queryParams).toString();
      authorizeEndpoint += authLabels.prompt;
      window.location.assign(authorizeEndpoint);
  });
  }

  getAuthLoadingState(): boolean {
    return this.LoaderService.getLoadingStatus();
  }
}
