import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthModule } from './auth/auth.module';

import { ErrorComponent } from './error/error.component';
import { LogoutComponent } from './shared/components/logout/logout.component';

const routes: Routes = [
  {path: 'auth',
    loadChildren: () => AuthModule},
  {path: '',
    redirectTo:'/auth',
  pathMatch:'full'},
  {path: 'feed',
    loadChildren: () => import('./feed/feed.module').then((module) => module.FeedModule)},
  {path: 'creator',
    loadChildren: () => import('./creator/creator.module').then((module) => module.CreatorModule)},
  {path: 'logout',
    component: LogoutComponent},
  {path: 'error',
    component: ErrorComponent},
  {path: '**',
    component: ErrorComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
