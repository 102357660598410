import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';

import { AuthStartComponent } from './auth-start/auth-start.component';
import { AuthEndComponent } from './auth-end/auth-end.component';
import { AuthPopupComponent } from './auth-popup/auth-popup.component';

@NgModule({
  declarations: [
    AuthStartComponent,
    AuthEndComponent,
    AuthPopupComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule
  ]
})
export class AuthModule { }
