export const authLabels = {
    response_type : 'id_token token',
    response_mode : 'fragment',
    redirect_uri : '/auth/auth-end',
    prompt : '&prompt=select_account',
    idp_cookie_name : 'kudos_idp',
    aws_cookie_name : 'kudos_aws'
};


export const creatorLabels = {
    appreciate_searchbar_placeholder : 'Who would you like to recognize?',
    max_users_selected_message : 'Maximum Users Selected !',
    post_content_placeholder : 'What did they do? How are they remarkable?',
    recognition_button : 'Give Recognition',
    carousel : 'Select the Core Value that best matches the spirit of your nomination.'
};


export const recognitionCardLabels = {
    recognized_by : 'RECOGNIZED BY',
    comment_button : 'POST'
};


export const navbarLabels = {
    feed : 'Feed',
    creator : 'Creator'
};

export const cookieLabels = {
    idpCookie: 'kudos_idp=;expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None;Secure;path=/;',
    awsCookie: 'kudos_aws=;expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None;Secure;path=/;'
};

export const errorLabels = {
    userWhitelistError: 'User does not have access currently.'
};
