import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

import { getCookie } from '../utils/getCookie';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const localDev = window.location.href.indexOf('.com') === -1;
    if (localDev) {
      req = this.applyCorsPolicy(req);
    }

    if (getCookie('kudos_aws') !== null) {
      const cognitoCookie = JSON.parse(getCookie('kudos_aws') || '{}');
      const cognitoToken = cognitoCookie.idtoken;
      req = req.clone({
        headers: req.headers.set('Authorization', String(cognitoToken))
      });
    }
    return next.handle(req);
  }

  private applyCorsPolicy(req: HttpRequest<any>): HttpRequest<any> {
      return req.clone({
        url: req.url.replace('https://dev.api.kudos.wginb.com', '')
      });
  }
}

