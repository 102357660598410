export const environment = {
    AAD_LOGIN_PARAMS: {
        CLIENT_ID: 'd24048e4-4a6f-4b05-9e45-5d055057507a',
        TENANT_ID: '2563c132-88f5-466f-bbb2-e83153b3c808',
        SCOPES: 'https://graph.microsoft.com/user.read openid profile email',
        NONCE: 'f88b23d4-3f79-4eaf-9f0a-ada82dba494d',
        AD_URL: 'https://login.microsoftonline.com/2563c132-88f5-466f-bbb2-e83153b3c808/oauth2/v2.0/authorize?'
    },
    ORIGIN_URL: 'https://dev.app.kudos.wginb.com/',
    SIGNUP_ENDPOINT: 'https://dev.api.kudos.wginb.com/wkapplogin/app/signup',
    LOGIN_ENDPOINT: 'https://dev.api.kudos.wginb.com/wkapplogin/app/login',
    GET_CORE_VALUES_ENDPOINT: 'https://dev.api.kudos.wginb.com/wkappapis/value/12345',
    GET_STATIC_DATA_ENDPOINT: 'https://dev.api.kudos.wginb.com/wkappapis/static',
    CREATE_POST_ENDPOINT: 'https://dev.api.kudos.wginb.com/wkappapis/post',
    GET_POST_ENDPOINT: `https://dev.api.kudos.wginb.com/wkappapis/post/list?quarter={0}&year={1}`,
    UPDATE_POST_ENDPOINT: 'https://dev.api.kudos.wginb.com/wkappapis/post/12345',
    GET_AAD_USERS_ENDPOINT: 'https://dev.api.kudos.wginb.com/wkappapis/ad/users?starts_with='
};
