import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, catchError, EMPTY } from 'rxjs';
import jwt_decode from 'jwt-decode';

import { LoginResponse } from '../models/auth-aws.model';
import { environment } from 'src/environments/environment';
import { getCookie } from '../utils/getCookie';

@Injectable({
  providedIn: 'root'
})
export class AwsAuthenticationService {

  constructor(private http: HttpClient) { }

  getAccessTokenFromAWS(): Observable<LoginResponse> {
    const idpCookie = JSON.parse(getCookie('kudos_idp') || '{}');
    const idpToken = idpCookie.id_token;
    const idpPayload = JSON.parse(JSON.stringify(jwt_decode(idpToken)));

    this.http.post(environment.SIGNUP_ENDPOINT, idpPayload).pipe(
      catchError(() => {
        return EMPTY;
      })
    ).subscribe();

    return this.http.post<LoginResponse>(environment.LOGIN_ENDPOINT, idpPayload);
  }
}
