import { Component } from '@angular/core';
import { Router } from '@angular/router';

import * as microsoftTeams from '@microsoft/teams-js';

import { AwsAuthenticationService } from 'src/app/shared/services/aws-authentication.service';
import { authLabels } from 'src/app/shared/labels';
import { getCookie } from 'src/app/shared/utils/getCookie';
import { AuthloaderService } from 'src/app/shared/services/authloader.service';

@Component({
  selector: 'wg-auth-start',
  templateUrl: './auth-start.component.html',
  styleUrls: ['./auth-start.component.css']
})
export class AuthStartComponent {
  errorObj: Error = {
    name: '',
    message: ''
  };

  constructor(private router: Router, private authService: AwsAuthenticationService, private AuthLoader: AuthloaderService) {
    if (getCookie(authLabels.idp_cookie_name) != null && getCookie(authLabels.aws_cookie_name) != null) {
      this.AuthLoader.setLoadingStatus(true);
      this.router.navigate(['/feed']);
    } else if (getCookie(authLabels.idp_cookie_name) != null) {
      this.authService.getAccessTokenFromAWS().subscribe((response) => {
        document.cookie = 'kudos_aws=' + JSON.stringify(response) + ';SameSite=None;Secure';
      });
      this.AuthLoader.setLoadingStatus(true);
      this.router.navigate(['/feed']);
    } else {
      microsoftTeams.app.initialize().then(()=>{
        microsoftTeams.authentication.authenticate({
          url: window.location.origin + '/auth/auth-popup',
          width: 600,
          height: 535,
          successCallback: ((response: any) => {
            const idpResponse = JSON.stringify(response);
            document.cookie = 'kudos_idp=' + idpResponse + ';SameSite=None;Secure';
            this.authService.getAccessTokenFromAWS().subscribe((responses) => {
              document.cookie = 'kudos_aws=' + JSON.stringify(responses) + ';SameSite=None;Secure';
              this.AuthLoader.setLoadingStatus(true);
              this.router.navigate(['/feed']);
            });
          }),
          failureCallback: ((reason: string) => {
            this.errorObj = {
              name: 'Authentication Error',
              message: reason
            };
            this.router.navigate(['/error', {state:JSON.stringify(this.errorObj)}]);
          })
        });
      });
    }
    }
}
