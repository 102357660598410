export function getCookie(cookieName: string): string | null {
    const cookie: any = {};
    document.cookie.split(';').forEach(function(el) {
      const [key, value] = el.split('=');
      cookie[key.trim()] = value;
    });
    if (cookie[cookieName] !== null) {
      return cookie[cookieName];
    }
    return null;
}
