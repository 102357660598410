import { Component } from '@angular/core';

import * as microsoftTeams from '@microsoft/teams-js';

import { parseParms } from 'src/app/shared/utils/parseParams';


@Component({
  selector: 'wg-auth-end',
  templateUrl: './auth-end.component.html'
})
export class AuthEndComponent {
  constructor() {
    microsoftTeams.app.initialize();
    microsoftTeams.getContext(() => {
      const hashParams = parseParms(window.location.hash);
      microsoftTeams.authentication.notifySuccess(hashParams);
    });
  }
}
