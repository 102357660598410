import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
  HttpEvent
} from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Error } from '../models/error.model';
import { cookieLabels, errorLabels } from '../labels';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  errorObj: Error = {
    name : 'SessionTimedOut',
    description : '',
    message: 'SessionTimedOut'
  };
  constructor(private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error.status === 401) {
          if (error.error.Messages === errorLabels.userWhitelistError) {
            this.errorObj = {
              name: 'UserWhitelistError',
              description: '',
              message: error.Messages
            };
          } else {
            document.cookie = cookieLabels.awsCookie;
            document.cookie = cookieLabels.idpCookie;
          }
          this.router.navigate(['/error', {state: JSON.stringify(this.errorObj)}]);
        }
        return throwError(error);
      })
    );
  }
}
