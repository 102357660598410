<nav class="m-0 p-3 navbar navbar-expand-lg navbar-dark bg-dark w-100">
    <div class="d-flex justify-space-between w-100">
      <div class="navbar-collapse" id="navbarNav">
         <ul class="navbar-nav">
            <li class="nav-item">
               <a class="nav-link" [routerLink]="['/feed']" [routerLinkActive]="['active-tab']">{{ labels.feed }}</a>
            </li>
            <li class="nav-item">
               <a class="nav-link" [routerLink]="['/creator']" [routerLinkActive]="['active-tab']">{{ labels.creator }}</a>
            </li>
         </ul>
      </div>
      <div>
         <button (click)="logout()" class="btn btn-danger logout-btn">Logout</button>
      </div>
   </div>
 </nav>