import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { cookieLabels, navbarLabels } from '../shared/labels';

@Component({
  selector: 'wg-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  constructor(private router: Router) {}
  labels = navbarLabels;

  logout() {
    document.cookie = cookieLabels.awsCookie;
    document.cookie = cookieLabels.idpCookie;
    this.router.navigate(['logout']);
  }
}
