export const errors = {
    CancelledByUser : {
        name: 'Authentication Error',
        description: 'Click on Try Again and do not close the login pop-up window.',
        btnText: 'Try Again'
    },
    SessionTimedOut: {
        name: 'Session Timed Out',
        description: 'Your session has expired please Login in again.',
        btnText: 'Login'
    },
    UserWhitelistError : {
        name: 'Unauthorized',
        description: 'User does not have access to DEV environment.',
        btnText: ''
    }
};
