import { Component } from '@angular/core';
import { CheckAuthStatusService } from './shared/services/check-auth-status.service';
import { AuthloaderService } from './shared/services/authloader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'wk-app-ui';
  constructor(private authService: CheckAuthStatusService, private AuthLoader: AuthloaderService) { }

  getLoginState(): boolean {
    return this.authService.getAuthState();
  }

  getLoaderState(): boolean {
    return this.AuthLoader.getLoadingStatus();
  }

}
