import { Injectable } from '@angular/core';
import { getCookie } from '../utils/getCookie';

@Injectable({
  providedIn: 'root'
})
export class CheckAuthStatusService {

  constructor() { }

  getAuthState() {
    if (getCookie('kudos_idp') != null && getCookie('kudos_aws') != null) {
      return true;
    }
    return false;
  }
}
