import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthloaderService {
  isLoading: boolean = false;
  constructor() { }

  setLoadingStatus(state: boolean): void {
    this.isLoading = state;
  }

  getLoadingStatus(): boolean {
    return this.isLoading;
  }
}
