export function parseParms(str: string) {
    const pieces = str.split('&');
    const data: any = {};

    for (let i = 1; i < pieces.length; i++) {
        const parts = pieces[i].split('=');
        if (parts.length < 2) {
            parts.push('');
        }
        data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    }
    return data;
};
