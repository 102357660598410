import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'wg-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent {

  constructor(private router: Router) {};
  navigateToLogin() {
    this.router.navigate(['/auth']);
  }
}
