import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Error } from '../shared/models/error.model';
import { errors } from './errorMessages';

@Component({
  selector: 'wg-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  errorDescription: string = '';
  errorBtnText: string = '';
  errorObj!: Error;
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.url.subscribe((res) => {
      const stateObj = res[0].parameters;
      this.errorObj = JSON.parse(stateObj['state']);
      if (this.errorObj.name === 'UserWhitelistError') {
        const errorKey = this.errorObj.name;
        this.errorDescription = errors[errorKey as keyof typeof errors].description;
        this.errorBtnText = errors[errorKey as keyof typeof errors].btnText;
        this.errorObj.name = 'Unauthorized';
      }
      else {
        const errorKey = this.errorObj.message;
        this.errorDescription = errors[errorKey as keyof typeof errors].description;
        this.errorBtnText = errors[errorKey as keyof typeof errors].btnText;
      }
    });
  }

  navigateToLogin() {
    this.router.navigate(['/auth']);
  }
}
