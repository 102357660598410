import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthStartComponent } from './auth-start/auth-start.component';
import { AuthPopupComponent } from './auth-popup/auth-popup.component';
import { AuthEndComponent } from './auth-end/auth-end.component';

const routes: Routes = [
    {path: '',
      component: AuthStartComponent,
      pathMatch: 'full'},
    {path: 'auth-popup',
      component: AuthPopupComponent},
    {path: 'auth-end',
      component: AuthEndComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
